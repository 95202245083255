import React, { useState } from 'react'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { RowBetween } from '../Row'
import { CloseIcon, TYPE } from '../../theme'
import { ButtonError } from '../Button'
import { StakingInfo } from '../../state/stake/hooks'
// import { LoadingView, SubmittedView } from '../ModalViews'
import FormattedCurrencyAmount from '../FormattedCurrencyAmount'
import { useActiveWeb3React } from '../../hooks'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

export interface StakingModalProps {
  isOpen: boolean
  onProcess: () => void
  onDismiss: () => void
  stakingInfo: StakingInfo
}

export default function UnstakingModal({ isOpen, onDismiss, onProcess, stakingInfo }: StakingModalProps) {
  const { account } = useActiveWeb3React()

  // monitor call to help UI loading state
  const [hash, setHash] = useState<string | undefined>()
  //   const [attempting, setAttempting] = useState(false)

  function wrappedOndismiss() {
    setHash(undefined)
    // setAttempting(false)
    onDismiss()
  }

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!stakingInfo?.stakedAmount) {
    error = error ?? 'Enter an amount'
  }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOndismiss} maxHeight={90}>
      {/* {!attempting && !hash && ( */}
      {!hash && (
        <ContentWrapper gap="lg" className="c-withdrawModal">
          <RowBetween>
            <TYPE.mediumHeader className="c-withdrawModal_header">Withdraw</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOndismiss} />
          </RowBetween>
          {stakingInfo?.stakedAmount && (
            <AutoColumn justify="center" gap="md" className="c-withdrawModal_data">
              <TYPE.body fontWeight={600} fontSize={36}>
                {<FormattedCurrencyAmount currencyAmount={stakingInfo.stakedAmount} />}
              </TYPE.body>
              <TYPE.body>Deposited liquidity:</TYPE.body>
            </AutoColumn>
          )}
          {stakingInfo?.earnedAmount && (
            <AutoColumn justify="center" gap="md" className="c-withdrawModal_data">
              <TYPE.body fontWeight={600} fontSize={36}>
                {<FormattedCurrencyAmount currencyAmount={stakingInfo?.earnedAmount} />}
              </TYPE.body>
              <TYPE.body>Unclaimed MNY</TYPE.body>
            </AutoColumn>
          )}
          <TYPE.subHeader style={{ textAlign: 'center' }} className="c-withdrawModal_info">
            When you withdraw, your MNY is claimed and your liquidity is removed from the mining pool.
          </TYPE.subHeader>
          <ButtonError
            disabled={!!error}
            error={!!error && !!stakingInfo?.stakedAmount}
            onClick={onProcess}
            className="c-withdrawModal_action"
          >
            {error ?? 'Withdraw & Claim'}
          </ButtonError>
        </ContentWrapper>
      )}
      {/* {attempting && !hash && (
        <LoadingView onDismiss={wrappedOndismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.body fontSize={20} className="c-loadingModal_infoWithdraw">
              Withdrawing {stakingInfo?.stakedAmount?.toSignificant(4)} CAKE-V2
            </TYPE.body>
            <TYPE.body fontSize={20} className="c-loadingModal_infoClaim">
              Claiming {stakingInfo?.earnedAmount?.toSignificant(4)} MNY
            </TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {hash && (
        <SubmittedView onDismiss={wrappedOndismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader className="c-submittedModal_infoAction">Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20} className="c-submittedModal_infoWithdraw">Withdrew CAKE-V2!</TYPE.body>
            <TYPE.body fontSize={20} className="c-submittedModal_infoClaim">Claimed MNY!</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )} */}
    </Modal>
  )
}
