import React, { useState, useEffect } from 'react'
import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'
import styled from 'styled-components'
import { TYPE, StyledInternalLink } from '../../theme'
import DoubleCurrencyLogo from '../DoubleLogo'
import { ETHER, JSBI, TokenAmount } from '@pancakeswap/sdk'
import { ButtonPrimary } from '../Button'
import { StakingInfo } from '../../state/stake/hooks'
import { useColor } from '../../hooks/useColor'
import { currencyId } from '../../utils/currencyId'
import { Break } from './styled'
// import { Break, CardNoise, CardBGImage } from './styled'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { useTotalSupply } from '../../data/TotalSupply'
import { usePair } from '../../data/Reserves'
import { getPrices } from '../../connectors/ApiConnector'
import useUSDCPrice from '../../utils/useUSDCPrice'
// import { useCurrency } from '../../hooks/Tokens'

// import { useDerivedMintInfo } from '../../state/mint/hooks'
import { BIG_INT_SECONDS_IN_WEEK, BIG_INT_SECONDS_IN_MONTH } from '../../constants'

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
`};
`

const Wrapper = styled(AutoColumn)<{ showBackground: boolean; bgColor: any }>`
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '1')};
  background: linear-gradient(6deg, #000, #181818);
  color: ${({ theme, showBackground }) => (showBackground ? theme.text1 : theme.text1)} !important;

  ${({ showBackground }) =>
    showBackground &&
    `  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);`}
`

const TopSection = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr 120px;
  grid-gap: 0px;
  align-items: center;
  padding: 1rem;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 48px 1fr 96px;
  `};
`

const BottomSection = styled.div<{ showBackground: boolean }>`
  padding: 12px 16px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '0.4')};
  border-radius: 0 0 12px 12px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  z-index: 1;
`

export default function PoolCard({ stakingInfo }: { stakingInfo: StakingInfo }) {
  const token0 = stakingInfo.tokens[0]
  const token1 = stakingInfo.tokens[1]

  const currency0 = unwrappedToken(token0)
  const currency1 = unwrappedToken(token1)

  const isStaking = Boolean(stakingInfo.stakedAmount.greaterThan('0'))

  // get the color of the token
  const token = currency0 === ETHER ? token1 : token0
  const WETH = currency0 === ETHER ? token0 : token1
  const backgroundColor = useColor(token)

  const totalSupplyOfStakingToken = useTotalSupply(stakingInfo.stakedAmount.token)
  const [, stakingTokenPair] = usePair(...stakingInfo.tokens)

  const [bnbPrice, setBnbPrice] = useState(0)
  const [mnyPrice, setMnyPrice] = useState(0)

  useEffect(() => {
    const fetchData = async () => {
      const result = await getPrices()
      if (result.success) {
        setBnbPrice(result.data.bnbPrice)
        setMnyPrice(result.data.mnyPrice)
      }
    }
    fetchData()
  }, [])

  // let returnOverMonth: Percent = new Percent('0')

  let valueOfTotalStakedAmountInWETH: TokenAmount | undefined
  if (totalSupplyOfStakingToken && stakingTokenPair) {
    // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
    valueOfTotalStakedAmountInWETH = new TokenAmount(
      WETH,
      JSBI.divide(
        JSBI.multiply(
          JSBI.multiply(stakingInfo.totalStakedAmount.raw, stakingTokenPair.reserveOf(WETH).raw),
          JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
        ),
        totalSupplyOfStakingToken.raw
      )
    )
  }

  const poolRewardsPerMonth: number = stakingInfo
    ? stakingInfo.active
      ? parseFloat(stakingInfo.totalRewardRate?.multiply(BIG_INT_SECONDS_IN_MONTH).toSignificant(6))
      : 0
    : 0

  const poolTotalStakeInEth: number = valueOfTotalStakedAmountInWETH
    ? parseFloat(valueOfTotalStakedAmountInWETH?.toSignificant(6))
    : 0

  // get the USD value of staked WETH
  const USDPrice = useUSDCPrice(WETH)
  const valueOfTotalStakedAmountInUSDC =
    valueOfTotalStakedAmountInWETH && USDPrice?.quote(valueOfTotalStakedAmountInWETH)

    // console.log('APR INFO--')
    // console.log(poolRewardsPerMonth)
    // console.log(poolTotalStakeInEth)
    // console.log(bnbPrice)
    // console.log(mnyPrice)
    // console.log(poolRewardsPerMonth && poolTotalStakeInEth && bnbPrice && mnyPrice
    //   ? (12 * poolRewardsPerMonth / (poolTotalStakeInEth * (bnbPrice / mnyPrice)) * 100).toFixed(0)
    //   : '-')
    // console.log('--APR INFO')

  return (
    <Wrapper showBackground={isStaking} bgColor={backgroundColor} className="c-pool">
      <TopSection className="c-pool_header">
        <h3>
          <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={40} />
          {currency0.symbol}-{currency1.name}
        </h3>

        <StyledInternalLink to={`/stake/${currencyId(currency0)}/${currencyId(currency1)}`}>
          <ButtonPrimary>{isStaking ? 'Manage' : 'Deposit'}</ButtonPrimary>
        </StyledInternalLink>
      </TopSection>

      <StatContainer className="c-pool_stats">
        <RowBetween>
          <p> APR:</p>
          <p>
            {/* 12 * your_rewards_per_month / ( your_total_stake_in_ETH * ( ETH_price / MNY_price ) ) */}
            {poolRewardsPerMonth && poolTotalStakeInEth && bnbPrice && mnyPrice
              ? (12 * poolRewardsPerMonth / (poolTotalStakeInEth * (bnbPrice / mnyPrice)) * 100).toFixed(0) + '%'
              : '-'}
          </p>
        </RowBetween>
        <RowBetween>
          <p> Total deposited:</p>
          <p>
            {valueOfTotalStakedAmountInUSDC
              ? `$${valueOfTotalStakedAmountInUSDC.toFixed(0, { groupSeparator: ',' })}`
              : `${valueOfTotalStakedAmountInWETH?.toSignificant(4, { groupSeparator: ',' }) ?? '-'} BNB`}
          </p>
        </RowBetween>
        <RowBetween>
          <p> Pool rate:</p>
          <p>
            {stakingInfo
              ? stakingInfo.active
                ? `${stakingInfo.totalRewardRate
                    ?.multiply(BIG_INT_SECONDS_IN_WEEK)
                    ?.toFixed(0, { groupSeparator: ',' })} MNY / week`
                : '0 MNY / week'
              : '-'}
          </p>
        </RowBetween>
      </StatContainer>

      <StyledInternalLink to={`/stake/${currencyId(currency0)}/${currencyId(currency1)}`}>
        <ButtonPrimary>{isStaking ? 'Manage' : 'Deposit'}</ButtonPrimary>
      </StyledInternalLink>

      {isStaking && (
        <>
          <Break className="c-pool_break" />
          <BottomSection showBackground={true} className="c-pool_participating">
            <TYPE.black color={'white'} fontWeight={500}>
              <span>Your rate:</span>
            </TYPE.black>

            <TYPE.black style={{ textAlign: 'right' }} color={'white'} fontWeight={500}>
              {/* <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
                ⚡
              </span> */}
              {stakingInfo
                ? stakingInfo.active
                  ? `${stakingInfo.rewardRate
                      ?.multiply(BIG_INT_SECONDS_IN_WEEK)
                      ?.toSignificant(4, { groupSeparator: ',' })} MNY / week`
                  : '0 MNY / week'
                : '-'}
            </TYPE.black>
          </BottomSection>
        </>
      )}
    </Wrapper>
  )
}
