import React from 'react'
import { ChainId } from '@pancakeswap/sdk'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'
import { STAKING_REWARDS_INFO, useStakingInfo } from '../../state/stake/hooks'
// import { ExternalLink, TYPE } from '../../theme'
import PoolCard from '../../components/earn/PoolCard'
import { RowBetween } from '../../components/Row'
// import {RowBetween, RowFlat} from '../../components/Row'
// import { CardBGImage, CardNoise, CardSection, DataCard } from '../../components/earn/styled'
// import { Countdown } from './Countdown'
import Loader from '../../components/Loader'
import { useActiveWeb3React } from '../../hooks'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

// const TopSection = styled(AutoColumn)`
//   max-width: 720px;
//   width: 100%;
// `

const PoolSection = styled.div``

const DataRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
flex-direction: column;
`};
`

export default function Earn() {
  const { chainId } = useActiveWeb3React()

  // staking info for connected account
  const stakingInfos = useStakingInfo()

  // toggle copy if rewards are inactive
  const stakingRewardsExist = Boolean(typeof chainId === 'number' && (STAKING_REWARDS_INFO[chainId]?.length ?? 0) > 0)

  return (
    <PageWrapper className="c-stakingPools">
      <AutoColumn className="c-stakingPools_header">
        <h1>BSC Liquidity Staking</h1>
        <p>Deposit your Liquidity Provider tokens to receive MNY. </p>
      </AutoColumn>

      <AutoColumn className="c-stakingPools_content">
        <DataRow className="c-stakingPools_subheader">
          <h2>Participating pools</h2>
          {/* <strong className="c-stakingPools_countdown">
            <Countdown exactEnd={stakingInfos?.[0]?.periodFinish} />
          </strong> */}
        </DataRow>

        <PoolSection className="c-stakingPools_pools">
          {stakingRewardsExist && stakingInfos?.length === 0 ? (
            <Loader style={{ margin: 'auto' }} className="c-stakingPools_poolsLoader" />
          ) : !stakingRewardsExist ? (
            chainId !== ChainId.MAINNET ?
              <p>Please connect to the Binance Smart Chain network.</p> :
              <p>No active pools</p>
          ) : (
            stakingInfos?.map(stakingInfo => {
              // need to sort by added liquidity here
              return <PoolCard key={stakingInfo.stakingRewardAddress} stakingInfo={stakingInfo} />
            })
          )}
        </PoolSection>
      </AutoColumn>
    </PageWrapper>
  )
}
