import React from 'react'
import { CurrencyAmount, Fraction, JSBI } from '@pancakeswap/sdk'

const CURRENCY_AMOUNT_MIN = new Fraction(JSBI.BigInt(1), JSBI.BigInt(1000000))

export default function FormattedCurrencyAmount({
  currencyAmount,
  significantDigits = 4,
  fee = 0
}: {
  currencyAmount: CurrencyAmount
  significantDigits?: number
  fee?: number
}) {
  return (
    <>
      {currencyAmount.equalTo(JSBI.BigInt(0))
        ? '0'
        : currencyAmount.greaterThan(CURRENCY_AMOUNT_MIN)
        ? ((1 - fee) * parseFloat(currencyAmount.toSignificant(significantDigits))).toFixed(2)
        : `<${CURRENCY_AMOUNT_MIN.toSignificant(1)}`}
    </>
  )
}
