import React, { useState } from 'react'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { RowBetween } from '../Row'
import { CloseIcon, TYPE } from '../../theme'
import { ButtonError } from '../Button'
import { StakingInfo } from '../../state/stake/hooks'
import { useStakingContract } from '../../hooks/useContract'
import { LoadingView, SubmittedView } from '../ModalViews'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
// import FormattedCurrencyAmount from '../FormattedCurrencyAmount'
import { useActiveWeb3React } from '../../hooks'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`
export interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakingInfo: StakingInfo
  onSubmitted: (string) => void
}

export default function UnstakingModal({ isOpen, onDismiss, stakingInfo, onSubmitted }: StakingModalProps) {
  const { account } = useActiveWeb3React()

  // monitor call to help UI loading state
  const addTransaction = useTransactionAdder()
  const [hash, setHash] = useState<string | undefined>()
  const [attempting, setAttempting] = useState(false)

  function wrappedOndismiss() {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }

  const stakingContract = useStakingContract(stakingInfo.stakingRewardAddress)

  async function onWithdraw() {
    if (stakingContract && stakingInfo?.stakedAmount) {
      setAttempting(true)
      await stakingContract
        .initializeWithdrawal({ gasLimit: 300000 })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Withdraw deposited liquidity`
          })
          setHash(response.hash)
          onSubmitted(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    }
  }

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  
  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOndismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg" className="c-withdrawModal -confirm">
          <RowBetween>
            <TYPE.mediumHeader className="c-withdrawModal_header">Commit to unstake</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOndismiss} />
          </RowBetween>
          <p style={{ textAlign: 'center' }} className="c-withdrawModal_info -i0">
            You are about to start unbounding period for your staked tokens. <strong>This action will first claim any earned rewards.</strong>
          </p>
          <p style={{ textAlign: 'center' }} className="c-withdrawModal_info -i1">
            During the 7-day unbounding period, your tokens will be locked and you won’t get any rewards and won’t be able to qualify for the IDO
          </p>
          <p style={{ textAlign: 'center' }} className="c-withdrawModal_info -i2">
            You can choose to instantly unlock your tokens and skip the unbounding period for a fee, 10% of your staked tokens.
          </p>
          <p style={{ textAlign: 'center' }} className="c-withdrawModal_period">
            Unbounding Period 7 days
          </p>
          <ButtonError
            disabled={!!error}
            error={!!error && !!stakingInfo?.stakedAmount}
            onClick={onWithdraw}
            className="c-withdrawModal_action"
          >
            {error ?? 'Confirm'}
          </ButtonError>
          <ButtonError
            disabled={false}
            error={false}
            onClick={onDismiss}
            className="c-withdrawModal_cancel"
          >
            Cancel
          </ButtonError>
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOndismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.body fontSize={20} className="c-loadingModal_infoWithdraw">
              Withdrawing {stakingInfo?.stakedAmount?.toSignificant(4)} CAKE-V2
            </TYPE.body>
            <TYPE.body fontSize={20} className="c-loadingModal_infoClaim">
              Claiming {stakingInfo?.earnedAmount?.toSignificant(4)} MNY
            </TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {hash && (
        <SubmittedView onDismiss={wrappedOndismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader className="c-submittedModal_infoAction">Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20} className="c-submittedModal_infoWithdraw">Withdrew CAKE-V2!</TYPE.body>
            <TYPE.body fontSize={20} className="c-submittedModal_infoClaim">Claimed MNY</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
