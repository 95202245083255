import { Currency, CurrencyAmount, currencyEquals, ETHER, Token } from '@pancakeswap/sdk'
import React, { CSSProperties, memo, useMemo } from 'react'
import { FixedSizeList } from 'react-window'
import { Text } from 'rebass'
// import { ThemeContext } from 'styled-components'
import { useActiveWeb3React } from '../../hooks'
import { useAllTokens } from '../../hooks/Tokens'
// import { useDefaultTokenList } from '../../state/lists/hooks'
import { useDefaultTokenList } from '../../state/lists/hooks'
// import { useAddUserToken, useRemoveUserAddedToken } from '../../state/user/hooks'
import { useETHBalances } from '../../state/wallet/hooks'
// import { LinkStyledButton, TYPE } from '../../theme'
// import { ButtonSecondary } from '../Button'
import Column from '../Column'
import { RowFixed } from '../Row'
import CurrencyLogo from '../CurrencyLogo'
// import { FadedSpan, MenuItem } from './styleds'
import { MenuItem } from './styleds'
import Loader from '../Loader'
import { isDefaultToken } from '../../utils'
// import { MouseoverTooltip } from '../Tooltip'
import styled from 'styled-components'
import { isTokenOnList } from '../../utils'

const StyledBalanceText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  max-width: 5rem;
  text-overflow: ellipsis;
`

// const Tag = styled.div`
//   background-color: ${({ theme }) => theme.bg3};
//   color: ${({ theme }) => theme.text2};
//   font-size: 14px;
//   border-radius: 4px;
//   padding: 0.25rem 0.3rem 0.25rem 0.3rem;
//   max-width: 6rem;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   justify-self: flex-end;
//   margin-right: 4px;
// `

function currencyKey(currency: Currency): string {
  return currency instanceof Token ? currency.address : currency === ETHER ? 'ETHER' : ''
}

function Balance({ balance }: { balance: CurrencyAmount }) {
  return <StyledBalanceText title={balance.toExact()}>{balance.toSignificant(4)}</StyledBalanceText>
}

// const TagContainer = styled.div`
//   display: flex;
//   justify-content: flex-end;
// `

export default function CurrencyList({
  currencies,
  allBalances,
  selectedCurrency,
  onCurrencySelect,
  otherCurrency,
  showSendWithSwap
}: {
  currencies: Currency[]
  selectedCurrency: Currency
  allBalances: { [tokenAddress: string]: CurrencyAmount }
  onCurrencySelect: (currency: Currency) => void
  otherCurrency: Currency
  showSendWithSwap?: boolean
}) {
  const { account } = useActiveWeb3React()
  //   const theme = useContext(ThemeContext)
  const allTokens = useAllTokens()
  const defaultTokens = useDefaultTokenList()
  const isOnSelectedList = isTokenOnList(defaultTokens, selectedCurrency)
  //   const addToken = useAddUserToken()
  //   const removeToken = useRemoveUserAddedToken()
  const ETHBalance = useETHBalances([account])[account]

  const CurrencyRow = useMemo(() => {
    return memo(function CurrencyRow({ index, style }: { index: number; style: CSSProperties }) {
      const currency = index === 0 ? Currency.ETHER : currencies[index - 1]
      const key = currencyKey(currency)
      const isDefault = isDefaultToken(defaultTokens, currency)
      const customAdded = Boolean(!isDefault && currency instanceof Token && allTokens[currency.address])
      const balance = currency === ETHER ? ETHBalance : allBalances[key]

      // const zeroBalance = balance && JSBI.equal(JSBI.BigInt(0), balance.raw)

      const isSelected = Boolean(selectedCurrency && currencyEquals(currency, selectedCurrency))
      const otherSelected = Boolean(otherCurrency && currencyEquals(otherCurrency, currency))

      return (
        <MenuItem
          style={style}
          className={`token-item-${key} c-currencyList_item`}
          onClick={() => (isSelected ? null : onCurrencySelect(currency))}
          disabled={isSelected}
          selected={otherSelected}
        >
          <CurrencyLogo currency={currency} size={'32px'} />
          <Column>
            <strong title={currency.name}>{currency.symbol}</strong>
            <p>
              {currency.name} {!isOnSelectedList && customAdded && '• Added by user'}
            </p>
          </Column>
          <div></div>
          <RowFixed style={{ justifySelf: 'flex-end' }}>
            <b>{balance ? <Balance balance={balance} /> : account ? <Loader /> : null}</b>
          </RowFixed>
        </MenuItem>
      )
    })
  }, [
    ETHBalance,
    account,
    allBalances,
    allTokens,
    currencies,
    defaultTokens,
    onCurrencySelect,
    otherCurrency,
    selectedCurrency,
    isOnSelectedList
  ])

  return (
    <FixedSizeList
      width="100%"
      height={500}
      itemCount={currencies.length + 1}
      itemSize={56}
      style={{ flex: '1' }}
      itemKey={index => currencyKey(currencies[index])}
      className="c-selectModal_row -list"
    >
      {CurrencyRow}
    </FixedSizeList>
  )
}
