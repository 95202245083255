import React, { useState } from 'react'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { RowBetween } from '../Row'
import { CloseIcon, TYPE } from '../../theme'
import { ButtonError } from '../Button'
import { StakingInfo } from '../../state/stake/hooks'
import { useStakingContract } from '../../hooks/useContract'
import { LoadingView, SubmittedView } from '../ModalViews'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import FormattedCurrencyAmount from '../FormattedCurrencyAmount'
import { useActiveWeb3React } from '../../hooks'
import { CountdownPeriod } from './CountdownPeriod'
// import { BigNumber } from '@ethersproject/bignumber'
// import { Database } from 'react-feather'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`
export interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakingInfo: StakingInfo
  withdrawTime: number
  onSubmitted: (string) => void
}

export default function UnstakingModal({
  isOpen,
  onDismiss,
  stakingInfo,
  withdrawTime,
  onSubmitted
}: StakingModalProps) {
  const { account } = useActiveWeb3React()

  // monitor call to help UI loading state
  const addTransaction = useTransactionAdder()
  const [hash, setHash] = useState<string | undefined>()
  const [instant, setInstant] = useState(false)
  const [attempting, setAttempting] = useState(false)
  const [periodEnd, setPeriodEnd] = useState(withdrawTime < Date.now())

  function wrappedOndismiss() {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }

  const stakingContract = useStakingContract(stakingInfo.stakingRewardAddress)

  async function onWithdraw() {
    if (stakingContract && stakingInfo?.stakedAmount) {
      setAttempting(true)
      await stakingContract
        .exit({ gasLimit: 300000 })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Withdraw deposited liquidity`
          })
          onSubmitted(response.hash)
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    }
  }

  async function onImmediateWithdraw() {
    if (stakingContract && stakingInfo?.stakedAmount) {
      setAttempting(true)
      await stakingContract
        .exitImmediately({ gasLimit: 300000 })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Withdraw deposited liquidity`
          })
          onSubmitted(response.hash)
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    }
  }

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOndismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg" className="c-withdrawModal -options">
          <RowBetween>
            <TYPE.mediumHeader className="c-withdrawModal_header">Withdraw</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOndismiss} />
          </RowBetween>
          {stakingInfo?.stakedAmount && (
            <AutoColumn justify="center" gap="md" className="c-withdrawModal_data -d0">
              <TYPE.body fontWeight={600} fontSize={36}>
                {<FormattedCurrencyAmount currencyAmount={stakingInfo.stakedAmount} />} LP
              </TYPE.body>
              <TYPE.body>Onbounding</TYPE.body>
            </AutoColumn>
          )}
          {/* {period && ( */}
          <AutoColumn justify="center" gap="md" className="c-withdrawModal_data -d1">
            <TYPE.body fontWeight={600} fontSize={36}>
              <CountdownPeriod exactEnd={new Date(withdrawTime)} callback={() => setPeriodEnd(true)}></CountdownPeriod>
            </TYPE.body>
            <TYPE.body>Unbonding period</TYPE.body>
          </AutoColumn>
          {/* )} */}
          <div className="c-withdrawModal_wrapper -w1">
            <p className="c-withdrawModal_info -i0">Unstaking Options</p>
            <div className="c-withdrawModal_options">
              <button
                className={`c-withdrawModal_option ${instant ? '' : '-checked'}`}
                onClick={() => setInstant(false)}
              >
                <p className="c-withdrawModal_optionLabel">
                  <strong>Standard Unstaking</strong>
                  Unbonding
                </p>
                <p className="c-withdrawModal_optionTokens">
                  <strong>
                    {!periodEnd ? (
                      '0.00'
                    ) : (
                      <FormattedCurrencyAmount currencyAmount={stakingInfo.stakedAmount} />
                    )}{' '}
                  </strong>{' '}
                  LP Token
                </p>
                <p className="c-withdrawModal_optionFee">No Fee</p>
              </button>
              <button
                className={`c-withdrawModal_option ${!instant ? '' : '-checked'}`}
                onClick={() => setInstant(true)}
              >
                <p className="c-withdrawModal_optionLabel">
                  <strong>Instant Unstaking</strong>
                  No Unbonding
                </p>
                <p className="c-withdrawModal_optionTokens">
                  <strong>{<FormattedCurrencyAmount currencyAmount={stakingInfo.stakedAmount} fee={0.1} />}</strong> LP Token
                </p>
                <p className="c-withdrawModal_optionFee">Burn Fee 10%</p>
              </button>
            </div>
          </div>
          <div className="c-withdrawModal_wrapper -w2">
            {/* <AutoColumn justify="center" gap="md" className="c-withdrawModal_data -d2">
              <TYPE.body>1 UST</TYPE.body>
              <TYPE.body>Trading fee</TYPE.body>
            </AutoColumn>
            <AutoColumn justify="center" gap="md" className="c-withdrawModal_data -d3">
              <TYPE.body>{'< 0.5 UST'}</TYPE.body>
              <TYPE.body>Tx fee</TYPE.body>
            </AutoColumn> */}
          </div>
          <ButtonError
            disabled={!!error || (!withdrawTime && !instant) || (!instant && withdrawTime && !periodEnd)}
            error={!!error && !!stakingInfo?.stakedAmount}
            onClick={instant ? onImmediateWithdraw : onWithdraw}
            className="c-withdrawModal_action"
          >
            {error ?? 'Unstake'}
          </ButtonError>
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOndismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.body fontSize={20} className="c-loadingModal_infoWithdraw">
              Withdrawing {stakingInfo?.stakedAmount?.toSignificant(4)} CAKE-V2
            </TYPE.body>
            <TYPE.body fontSize={20} className="c-loadingModal_infoClaim">
              Claiming {stakingInfo?.earnedAmount?.toSignificant(4)} MNY
            </TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {hash && (
        <SubmittedView onDismiss={wrappedOndismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader className="c-submittedModal_infoAction">Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20} className="c-submittedModal_infoWithdraw">
              Withdrew CAKE-V2!
            </TYPE.body>
            <TYPE.body fontSize={20} className="c-submittedModal_infoClaim">
              Claimed MNY
            </TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
