import React from 'react'
import styled from 'styled-components'

const ToggleElement = styled.span<{ isActive?: boolean; isOnSwitch?: boolean }>`
  padding: 0;
  background: ${({ theme, isActive, isOnSwitch }) => (isActive ? (isOnSwitch ? theme.primary1 : theme.text4) : 'none')};
  color: ${({ theme, isActive, isOnSwitch }) => (isActive ? (isOnSwitch ? theme.white : theme.text2) : theme.text3)};
  font-size: 1rem;
  font-weight: 400;
  
  width 8px
  padding: 0;
  background: ${({ theme, isActive, isOnSwitch }) => (isActive ? 'white' : isOnSwitch ? '#BAC5DA' : '#FF469F')};
  color: ${({ theme, isActive, isOnSwitch }) => (isActive ? '#161623' : isOnSwitch ? '#BAC5DA' : '#FF469F')};
  font-size: 12px;
  line-height: 16px;
  font-family: "Grotesk", monospace;
  font-weight: 700;
  margin-right: ${({ isOnSwitch }) => (isOnSwitch ? '4px' : '0')};
  {/* 
  :hover {
    user-select: ${({ isOnSwitch }) => (isOnSwitch ? 'none' : 'initial')};
    background: ${({ theme, isActive, isOnSwitch }) =>
      isActive ? (isOnSwitch ? theme.primary1 : theme.text3) : 'none'};
    color: ${({ theme, isActive, isOnSwitch }) => (isActive ? (isOnSwitch ? theme.white : theme.text2) : theme.text3)};
  }
  */}
`

const StyledToggle = styled.button<{ isActive?: boolean; activeElement?: boolean }>`
  border: none;
  background: white;
  display: flex;
  width: fit-content;
  cursor: pointer;
  outline: none;
  padding: 4px;
  height: 24px;
`

export interface ToggleProps {
  id?: string
  isActive: boolean
  toggle: () => void
}

export default function Toggle({ id, isActive, toggle }: ToggleProps) {
  return (
    <StyledToggle id={id} isActive={isActive} onClick={toggle}>
      <ToggleElement isActive={isActive} isOnSwitch={true}>
        On
      </ToggleElement>
      <ToggleElement isActive={!isActive} isOnSwitch={false}>
        Off
      </ToggleElement>
    </StyledToggle>
  )
}
