import { ChainId, TokenAmount } from '@pancakeswap/sdk'
import React, { useEffect, useState } from 'react'
import { X } from 'react-feather'
import styled from 'styled-components'
import mnyTokenLogo from '../../assets/images/mny.svg'
import { VAI } from '../../constants'
import { useTotalSupply } from '../../data/TotalSupply'
import { useActiveWeb3React } from '../../hooks'
import { useTotalUniEarned } from '../../state/stake/hooks'
import { useAggregateUniBalance, useTokenBalance } from '../../state/wallet/hooks'
import { ExternalLink, StyledInternalLink, TYPE, UniTokenAnimated } from '../../theme'
import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'
import { Break, CardBGImage, CardNoise, CardSection, DataCard } from '../earn/styled'
import { getCirculation } from '../../connectors/ApiConnector'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
`

const ModalUpper = styled(DataCard)`
  background: linear-gradient(6deg, #000, #181818);
  padding: 0.5rem;
`

const StyledClose = styled(X)`
  position: absolute;
  right: 16px;
  top: 16px;

  :hover {
    cursor: pointer;
  }
`

/**
 * Content for balance stats modal
 */
export default function UniBalanceContent({ setShowUniBalanceModal }: { setShowUniBalanceModal: any }) {
  const { account, chainId } = useActiveWeb3React()
  const vai = chainId ? VAI[chainId] : undefined

  const total = useAggregateUniBalance()
  const uniBalance: TokenAmount | undefined = useTokenBalance(account ?? undefined, vai)
  const uniToClaim: TokenAmount | undefined = useTotalUniEarned()

  const totalSupply: TokenAmount | undefined = useTotalSupply(vai)
  const [uniPrice, setUniPrice] = useState(0)

  const [circulation, setCirculation] = useState(new TokenAmount(vai ?? VAI[1], '0'))

  useEffect(() => {
    const fetchData = async () => {
      const result = await getCirculation()
      if (result.success) {
        setUniPrice(Number(result.data?.usdPrice ?? '0'))
        setCirculation(new TokenAmount(vai ?? VAI[1], BigInt(result.data?.circulatingSupply ?? '0')))
      }
    }
    fetchData()
  }, [vai])

  return (
    <ContentWrapper gap="lg">
      <ModalUpper className="c-tokenInfoModal_wrapper">
        <CardBGImage />
        <CardNoise />
        <CardSection gap="md">
          <RowBetween>
            <TYPE.white color="black" className="c-tokenInfoModal_title">
              Your MNY Breakdown
            </TYPE.white>
            <StyledClose
              stroke="black"
              onClick={() => setShowUniBalanceModal(false)}
              className="c-tokenInfoModal_close"
            />
          </RowBetween>
        </CardSection>
        <Break />
        {account && (
          <>
            <CardSection gap="sm">
              <AutoColumn gap="md" justify="center">
                <UniTokenAnimated width="48px" src={mnyTokenLogo} className="c-tokenInfoModal_tokenAnimated" />{' '}
                <TYPE.white fontSize={56} fontWeight={700} color="black" className="c-tokenInfoModal_mainBalance">
                  {total?.toFixed(2, { groupSeparator: ',' })}
                </TYPE.white>
              </AutoColumn>
              <AutoColumn gap="md">
                <RowBetween>
                  <TYPE.white color="black" className="c-tokenInfoModal_text">
                    Balance:
                  </TYPE.white>
                  <TYPE.white color="black" className="c-tokenInfoModal_text">
                    {uniBalance?.toFixed(2, { groupSeparator: ',' })}
                  </TYPE.white>
                </RowBetween>
                <RowBetween>
                  <TYPE.white color="black" className="c-tokenInfoModal_text">
                    Unclaimed:
                  </TYPE.white>
                  <TYPE.white color="black" className="c-tokenInfoModal_text">
                    {uniToClaim?.toFixed(4, { groupSeparator: ',' })}{' '}
                    {uniToClaim && uniToClaim.greaterThan('0') && (
                      <StyledInternalLink
                        onClick={() => setShowUniBalanceModal(false)}
                        to="/stake"
                        className="c-tokenInfoModal_link"
                      >
                        (Claim)
                      </StyledInternalLink>
                    )}
                  </TYPE.white>
                </RowBetween>
              </AutoColumn>
            </CardSection>
            <Break className="c-tokenInfoModal_break" />
          </>
        )}
        <CardSection gap="sm">
          <AutoColumn gap="md">
            <RowBetween>
              <TYPE.white color="black" className="c-tokenInfoModal_text">
                MNY price:
              </TYPE.white>
              <TYPE.white color="black" className="c-tokenInfoModal_text">
                ${uniPrice?.toFixed(2) ?? '-'}
              </TYPE.white>
            </RowBetween>
            <RowBetween>
              <TYPE.white color="black" className="c-tokenInfoModal_text">
                MNY in circulation:
              </TYPE.white>
              <TYPE.white color="black" className="c-tokenInfoModal_text">
                {circulation?.toFixed(0, { groupSeparator: ',' })}
              </TYPE.white>
            </RowBetween>
            <RowBetween>
              <TYPE.white color="black" className="c-tokenInfoModal_text">
                Total Supply
              </TYPE.white>
              <TYPE.white color="black" className="c-tokenInfoModal_text">
                {totalSupply?.toFixed(0, { groupSeparator: ',' })}
              </TYPE.white>
            </RowBetween>
            {vai && vai.chainId === ChainId.MAINNET ? (
              <ExternalLink
                href={`https://pancakeswap.finance/info/token/${vai.address}`}
                className="c-tokenInfoModal_externalLink"
              >
                View MNY Analytics
              </ExternalLink>
            ) : null}
          </AutoColumn>
        </CardSection>
      </ModalUpper>
    </ContentWrapper>
  )
}
