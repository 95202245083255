import React, { useCallback, useEffect, useState } from 'react'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { JSBI, TokenAmount, ETHER } from '@pancakeswap/sdk'
import { RouteComponentProps } from 'react-router-dom'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { useCurrency } from '../../hooks/Tokens'
import { useBlockNumber, useWalletModalToggle } from '../../state/application/hooks'
import { TYPE } from '../../theme'

import { RowBetween } from '../../components/Row'
import { CardSection, DataCard } from '../../components/earn/styled'
import { ButtonPrimary, ButtonEmpty } from '../../components/Button'
import StakingModal from '../../components/earn/StakingModal'
import { useStakingInfo, WITHDRAWAL_GENESIS } from '../../state/stake/hooks'
import UnstakingModal from '../../components/earn/UnstakingModal'
import UnstakingConfirmModal from '../../components/earn/UnstakingConfirmModal'
import UnstakingOptionsModal from '../../components/earn/UnstakingOptionsModal'
import ClaimRewardModal from '../../components/earn/ClaimRewardModal'
import { useTokenBalance } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { useColor } from '../../hooks/useColor'
import { CountUp } from 'use-count-up'
import { useStakingContract } from '../../hooks/useContract'
import { TransactionResponse } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'

import { wrappedCurrency } from '../../utils/wrappedCurrency'
import { currencyId } from '../../utils/currencyId'
import { useTotalSupply } from '../../data/TotalSupply'
import { usePair } from '../../data/Reserves'
import usePrevious from '../../hooks/usePrevious'
import useUSDCPrice from '../../utils/useUSDCPrice'
import { BIG_INT_ZERO, BIG_INT_SECONDS_IN_WEEK } from '../../constants'
import { Countdown } from './Countdown'

export const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

export const PositionInfo = styled(AutoColumn)<{ dim: any }>`
  position: relative;
  max-width: 640px;
  width: 100%;
`

export const BottomSection = styled(AutoColumn)`
  border-radius: 12px;
  width: 100%;
  position: relative;
`

export const StyledDataCard = styled(DataCard)<{ bgColor?: any; showBackground?: any }>``

export const StyledBottomCard = styled(DataCard)<{ dim: any }>`
  background: ${({ theme }) => theme.bg3};
  margin-top: -40px;
  padding: 0 1.25rem 1rem 1.25rem;
  padding-top: 32px;
  z-index: 1;
`

export const PoolData = styled(DataCard)`
  background: none;
  border: 1px solid ${({ theme }) => theme.bg4};
  padding: 1rem;
  z-index: 1;
`

export const VoteCard = styled(DataCard)`
  background: linear-gradient(160deg, #000, #6360b5);
  overflow: hidden;
`

export const DataRow = styled(RowBetween)`
  justify-content: center;
  gap: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 12px;
  `};
`

export function IsWithdrawalAccessible(end: number) {
  const [time, setTime] = useState(() => Math.floor(Date.now() / 1000))
  const [isWithdrawalAccessible, setIsWithdrawalAccessible] = useState(() => false)
  useEffect((): (() => void) | void => {
    // we only need to tick if withdrawal is currently inaccessible
    if (time <= end) {
      const timeout = setTimeout(() => setTime(Math.floor(Date.now() / 1000)), 1000)
      return () => {
        clearTimeout(timeout)
      }
    } else {
      setIsWithdrawalAccessible(true)
    }
  }, [time, end, isWithdrawalAccessible])
  return isWithdrawalAccessible
}

export default function Manage({
  match: {
    params: { currencyIdA, currencyIdB }
  }
}: RouteComponentProps<{ currencyIdA: string; currencyIdB: string }>) {
  const { account, chainId } = useActiveWeb3React()

  // get currencies and pair
  const [currencyA, currencyB] = [useCurrency(currencyIdA), useCurrency(currencyIdB)]
  const tokenA = wrappedCurrency(currencyA ?? undefined, chainId)
  const tokenB = wrappedCurrency(currencyB ?? undefined, chainId)

  const [, stakingTokenPair] = usePair(tokenA, tokenB)
  const stakingInfo = useStakingInfo()?.[0]

  // detect existing unstaked LP position to show add button if none found
  const userLiquidityUnstaked = useTokenBalance(account ?? undefined, stakingInfo?.stakedAmount?.token)
  const showAddLiquidityButton = Boolean(stakingInfo?.stakedAmount?.equalTo('0') && userLiquidityUnstaked?.equalTo('0'))

  // toggle for staking modal and unstaking modal
  const [showStakingModal, setShowStakingModal] = useState(false)
  const [showUnstakingModal, setShowUnstakingModal] = useState(false)
  const [showUnstakingConfirmModal, setShowUnstakingConfirmModal] = useState(false)
  const [showUnstakingOptionsModal, setShowUnstakingOptionsModal] = useState(false)
  const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)
  const [withdrawTime, setWithdrawTime] = useState<number | undefined>()
  const [pendingInitialWithdrawal, setPendingInitialWithdrawal] = useState<string | undefined>()
  const [pendingWithdrawal, setPendingWithdrawal] = useState<string | undefined>()
  const blockNumber = useBlockNumber()


  const stakingContract = useStakingContract(stakingInfo?.stakingRewardAddress)

  useEffect(() => {
    async function checkPeriod() {
      await stakingContract
        .withdrawTime(account, { gasLimit: 300000 })
        .then((response: TransactionResponse) => {
            const endTime = (BigNumber.from(response)).toNumber() * 1000
            
            // console.log('CYK')
            // console.log(endTime)

            if (pendingWithdrawal !== undefined) {
                if (endTime === 0 && !stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0))) {
                    setPendingWithdrawal(undefined)
                    setWithdrawTime(endTime)
                }
            } else {
                if (endTime !== 0 && withdrawTime !== undefined) {
                    setPendingInitialWithdrawal(undefined)
                    setTimeout(() => {
                        setShowUnstakingOptionsModal(true)
                    }, 1000);
                }
    
                setWithdrawTime(endTime)
            }
        })
        .catch((error: any) => {
          console.log(error)
        })
    }

    if (
      (withdrawTime === undefined && stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0))) ||
      pendingInitialWithdrawal ||
      pendingWithdrawal
    ) {
        checkPeriod()
    }
  }, [account, stakingContract, blockNumber, pendingInitialWithdrawal, stakingInfo, withdrawTime, pendingWithdrawal])

  // fade cards if nothing staked or nothing earned yet
  const disableTop = !stakingInfo?.stakedAmount || stakingInfo.stakedAmount.equalTo(JSBI.BigInt(0))
  const token = currencyA === ETHER ? tokenB : tokenA
  const WETH = currencyA === ETHER ? tokenA : tokenB
  const backgroundColor = useColor(token)

  // get WETH value of staked LP tokens
  const totalSupplyOfStakingToken = useTotalSupply(stakingInfo?.stakedAmount?.token)
  let valueOfTotalStakedAmountInWETH: TokenAmount | undefined
  if (totalSupplyOfStakingToken && stakingTokenPair && stakingInfo && WETH) {
    // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
    valueOfTotalStakedAmountInWETH = new TokenAmount(
      WETH,
      JSBI.divide(
        JSBI.multiply(
          JSBI.multiply(stakingInfo.totalStakedAmount.raw, stakingTokenPair.reserveOf(WETH).raw),
          JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
        ),
        totalSupplyOfStakingToken.raw
      )
    )
  }

  const countUpAmount = stakingInfo?.earnedAmount?.toFixed(6) ?? '0'
  const countUpAmountPrevious = usePrevious(countUpAmount) ?? '0'

  // get the USD value of staked WETH
  const USDPrice = useUSDCPrice(WETH)
  const valueOfTotalStakedAmountInUSDC =
    valueOfTotalStakedAmountInWETH && USDPrice?.quote(valueOfTotalStakedAmountInWETH)

  const toggleWalletModal = useWalletModalToggle()

  const handleDepositClick = useCallback(() => {
    if (account) {
      setShowStakingModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  const end = WITHDRAWAL_GENESIS

  const endDate = new Date(WITHDRAWAL_GENESIS * 1000)
  const isWithdrawalAccessible = IsWithdrawalAccessible(end)

  const handleWithdraw = () => {
    if (withdrawTime) {
        setShowUnstakingOptionsModal(true)
    } else {
        setShowUnstakingModal(true)
    }
  }

  return (
    <PageWrapper className="c-stakingManage">
      <RowBetween className="c-stakingManage_row -first">
        <h1>
          {currencyA?.symbol}-{currencyB?.symbol} Liquidity Staking
        </h1>
        <DoubleCurrencyLogo currency0={currencyA ?? undefined} currency1={currencyB ?? undefined} size={40} />
      </RowBetween>

      <RowBetween className="c-stakingManage_row -second">
        <PoolData>
          <AutoColumn>
            <strong>
              {valueOfTotalStakedAmountInUSDC
                ? `$${valueOfTotalStakedAmountInUSDC.toFixed(0, { groupSeparator: ',' })}`
                : `${valueOfTotalStakedAmountInWETH?.toSignificant(4, { groupSeparator: ',' }) ?? '-'} BNB`}
            </strong>
            <p>Total Deposits</p>
          </AutoColumn>
        </PoolData>
        <PoolData>
          <AutoColumn>
            <strong>
              {stakingInfo?.active
                ? stakingInfo?.totalRewardRate
                    ?.multiply(BIG_INT_SECONDS_IN_WEEK)
                    ?.toFixed(0, { groupSeparator: ',' }) ?? '-'
                : '0'}
              {' MNY / week'}
            </strong>
            <p>Pool Rate</p>
          </AutoColumn>
        </PoolData>
      </RowBetween>

      {showAddLiquidityButton && (
        <VoteCard className="c-stakingManage_row -third">
          <AutoColumn>
            <RowBetween>
              <strong>Step 1. Get CAKE-V2 Liquidity tokens</strong>
            </RowBetween>
            <RowBetween>
              <p>
                {`CAKE-V2 LP tokens are required. Once you've added liquidity to the ${currencyA?.symbol}-${currencyB?.symbol} pool you can stake your liquidity tokens on this page.`}
              </p>
            </RowBetween>
            <ButtonPrimary
              as={Link}
              to={`/add/${tokenA.address}/${currencyB && currencyId(currencyB)}`}
            >
              {`Add ${currencyA?.symbol}-${currencyB?.symbol} liquidity`}
            </ButtonPrimary>
          </AutoColumn>
        </VoteCard>
      )}

      {stakingInfo && (
        <>
          <StakingModal
            isOpen={showStakingModal}
            onDismiss={() => setShowStakingModal(false)}
            stakingInfo={stakingInfo}
            userLiquidityUnstaked={userLiquidityUnstaked}
          />
          <UnstakingModal
            isOpen={showUnstakingModal}
            onProcess={() => {
              setShowUnstakingModal(false)
              setShowUnstakingConfirmModal(true)
            }}
            onDismiss={() => setShowUnstakingModal(false)}
            stakingInfo={stakingInfo}
          />
          <UnstakingConfirmModal
            isOpen={showUnstakingConfirmModal}
            onDismiss={() => setShowUnstakingConfirmModal(false)}
            stakingInfo={stakingInfo}
            onSubmitted={(hash) => {setPendingInitialWithdrawal(hash)}}
          />
          <UnstakingOptionsModal
            isOpen={showUnstakingOptionsModal}
            onDismiss={() => setShowUnstakingOptionsModal(false)}
            stakingInfo={stakingInfo}
            withdrawTime={withdrawTime}
            onSubmitted={(hash) => {
                setPendingWithdrawal(hash)
            }}
          />
          <ClaimRewardModal
            isOpen={showClaimRewardModal}
            onDismiss={() => setShowClaimRewardModal(false)}
            stakingInfo={stakingInfo}
          />
        </>
      )}

      <PositionInfo className="c-stakingManage_row -fourth" dim={showAddLiquidityButton}>
        <div className="c-stakingManage_rowContent">
          <StyledDataCard disabled={disableTop} bgColor={backgroundColor} showBackground={!showAddLiquidityButton}>
            <CardSection>
              <AutoColumn>
                <RowBetween>
                  <strong>{stakingInfo?.stakedAmount?.toSignificant(6) ?? '-'}</strong>
                </RowBetween>
                <RowBetween>
                  <p>Your liquidity deposits</p>
                  <p>
                    CAKE-V2 {currencyA?.symbol}-{currencyB?.symbol}
                  </p>
                </RowBetween>
              </AutoColumn>
            </CardSection>
          </StyledDataCard>
          <StyledBottomCard dim={stakingInfo?.stakedAmount?.equalTo(JSBI.BigInt(0))}>
            <AutoColumn className={showAddLiquidityButton ? `-hollow` : ''}>
              <RowBetween>
                <strong>
                  <CountUp
                    key={countUpAmount}
                    isCounting
                    decimalPlaces={4}
                    start={parseFloat(countUpAmountPrevious)}
                    end={parseFloat(countUpAmount)}
                    thousandsSeparator={','}
                    duration={1}
                  />
                </strong>

                {stakingInfo?.earnedAmount && JSBI.notEqual(BIG_INT_ZERO, stakingInfo?.earnedAmount?.raw) && (
                  <ButtonEmpty
                    padding="8px"
                    borderRadius="8px"
                    width="fit-content"
                    onClick={() => setShowClaimRewardModal(true)}
                    disabled={!isWithdrawalAccessible}
                    className="c-stakingManage_claimBtn"
                  >
                    Claim
                  </ButtonEmpty>
                )}
              </RowBetween>
              <RowBetween style={{ alignItems: 'baseline' }}>
                <p>Your unclaimed MNY</p>
                <p>
                  {stakingInfo?.active
                    ? stakingInfo?.rewardRate
                        ?.multiply(BIG_INT_SECONDS_IN_WEEK)
                        ?.toSignificant(4, { groupSeparator: ',' }) ?? '-'
                    : '0'}
                  {' MNY / week'}
                </p>
              </RowBetween>
            </AutoColumn>
          </StyledBottomCard>
        </div>
        <p>When you withdraw, the contract will automagically claim MNY on your behalf!</p>
        {!showAddLiquidityButton && (
          <DataRow className="c-stakingManage_rowActions">
            {stakingInfo && stakingInfo.active && (
              <ButtonPrimary
                padding="8px"
                borderRadius="8px"
                width="160px"
                onClick={handleDepositClick}
                disabled={withdrawTime !== 0 && withdrawTime !== undefined}
                className="c-stakingManage_action -dark"
              >
                {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) ? 'Deposit' : 'Deposit CAKE-V2 LP Tokens'}
              </ButtonPrimary>
            )}

            {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) && (
              <>
                <ButtonPrimary
                  padding="8px"
                  borderRadius="8px"
                  width="160px"
                  onClick={handleWithdraw}
                  disabled={
                    !isWithdrawalAccessible ||
                    withdrawTime === undefined ||
                    pendingInitialWithdrawal !== undefined ||
                    pendingWithdrawal !== undefined
                  }
                  className="c-stakingManage_action"
                >
                  Withdraw
                </ButtonPrimary>
              </>
            )}
          </DataRow>
        )}
        {!showAddLiquidityButton && (
          <Countdown exactEnd={endDate} withdraw={true} strong={!(stakingInfo && stakingInfo.active)} />
        )}
        {!userLiquidityUnstaked ? null : userLiquidityUnstaked.equalTo('0') ? null : !stakingInfo?.active ? null : (
          <TYPE.main className="c-stakingManage_balance">
            {userLiquidityUnstaked.toSignificant(6)} CAKE-V2 LP tokens available
          </TYPE.main>
        )}
      </PositionInfo>
    </PageWrapper>
  )
}
