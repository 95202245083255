import React from 'react'

import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const StyledSVG = styled.svg<{ size: string; stroke?: string }>`
  animation: 2s ${rotate} linear infinite;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  path {
    stroke: ${({ stroke, theme }) => stroke ?? theme.primary1};
  }
`

/**
 * Takes in custom size and stroke for circle color, default to primary color as fill,
 * need ...rest for layered styles on top
 */
export default function Loader({
  size = '108px',
  stroke,
  ...rest
}: {
  size?: string
  stroke?: string
  [k: string]: any
}) {
  return (
    <StyledSVG viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg" size={size} stroke={stroke} {...rest}>
      <path d="M105.3 54C106.791 54 108.007 55.2098 107.933 56.6991C107.338 68.5894 102.826 79.9791 95.0619 89.0702C86.7116 98.8472 75.1467 105.324 62.4475 107.335C49.7482 109.347 36.748 106.761 25.7851 100.043C14.8222 93.3245 6.61616 82.9152 2.64295 70.6869C-1.33026 58.4586 -0.809874 45.2139 4.1105 33.3351C9.03088 21.4563 18.0283 11.7229 29.4845 5.88565C40.9407 0.0484346 54.1037 -1.50952 66.606 1.49202C78.2312 4.28298 88.5757 10.8458 96.0458 20.1156C96.9815 21.2767 96.7089 22.9701 95.5026 23.8466C94.2962 24.7231 92.6133 24.4501 91.6712 23.2942C84.9616 15.0622 75.7202 9.23358 65.3454 6.74282C54.0934 4.04144 42.2466 5.44359 31.9361 10.6971C21.6255 15.9506 13.5278 24.7106 9.09945 35.4016C4.67112 46.0925 4.20277 58.0128 7.77866 69.0182C11.3545 80.0237 18.74 89.3921 28.6066 95.4383C38.4732 101.485 50.1734 103.812 61.6027 102.002C73.0321 100.191 83.4404 94.3624 90.9557 85.5632C97.885 77.45 101.936 67.3026 102.525 56.6989C102.608 55.21 103.809 54 105.3 54Z" fill="#0341F5"/>
    </StyledSVG>
  )
}
